import { template as template_e11836437865434f8fc449d0cc3ef9fb } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { modifier } from "ember-modifier";
import highlightSearch from "discourse/lib/highlight-search";
export default class HighlightSearch extends Component {
    highlight = modifier((element)=>{
        highlightSearch(element, this.args.highlight);
    });
    static{
        template_e11836437865434f8fc449d0cc3ef9fb(`
    <span {{this.highlight}}>{{yield}}</span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
