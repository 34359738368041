import { template as template_a91f1b4cba9840fb97cbe7212b43004a } from "@ember/template-compiler";
const FKControlMenuContainer = template_a91f1b4cba9840fb97cbe7212b43004a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
